import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import CopyAll from '@mui/icons-material/CopyAll';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { EXPORT_CONFIRMING_PAYROLL } from '../../../../graphql';
import { openUrlAndDownload } from '../../../../helpers';
import { DownloadExcelIconButton } from '../../../../components/buttons';
import FingoBankAccount from '../../../../components/text/FingoBankAccount';

const CurrentPayrollRowActions = ({ payrollId }) => {
  const [open, setOpen] = useState(false);
  const [exportConfirmingPayroll, { loading: loadingExcel }] = useLazyQuery(
    EXPORT_CONFIRMING_PAYROLL,
    { onCompleted: (data) => openUrlAndDownload(data.exportConfirmingPayroll, 'payrollWorkbook') },
  );
  const handleCopy = () => {
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(
      'Nombre: Fingo SPA\nBanco Santander Rut: 77.201.556-9\nNúmero de cuenta: 000076829241\nEmail: operaciones@fingo.cl',
    );
  };
  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      {payrollId && (
      <DownloadExcelIconButton
        title="Descargar excel de nómina"
        loading={loadingExcel}
        downloadFunction={() => exportConfirmingPayroll({ variables: { payrollId } })}
      />
      )}
      <Tooltip
        title="Ver datos bancarios"
      >
        <LoadingButton
          color="primary"
          size="small"
          onClick={() => setOpen(true)}
        >
          <u>Pagar →</u>
        </LoadingButton>
      </Tooltip>
      <Drawer
        open={open}
        onClose={() => { setOpen(false); }}
        anchor="right"
        PaperProps={{
          sx: { width: { xs: '100%', md: '50%', lg: '30%' } },
        }}
        sx={{ zIndex: 1100, position: 'absolute' }}
      >
        <Stack my={2} direction="row" alignItems="center">
          <IconButton onClick={() => setOpen(false)} size="large">
            <ArrowBackIos />
          </IconButton>
          <Stack direction="row" alignItems="center">
            <Typography color="primary" variant="h6">
              Datos bancarios de Fingo
            </Typography>
            <Tooltip title="Copiar datos bancarios" sx={{ ml: 4 }}>
              <IconButton
                size="small"
                onClick={handleCopy}
              >
                <CopyAll />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack width="100%" px={4} fontSize="14px">
          <FingoBankAccount />
        </Stack>
      </Drawer>
    </Stack>
  );
};

CurrentPayrollRowActions.propTypes = {
  payrollId: PropTypes.string.isRequired,
};

export default CurrentPayrollRowActions;
