import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FingoMainView from '../../components/layout/FingoMainView';
import { payrollColumns } from './columns';
import ConfirmingPortfolioOperations from './ConfirmingPortfolioOperations';
import ConfirmingPortfolioFilters from './ConfirmingPortfolioFilters';
import FingoTable from '../../components/tables/FingoTable';
import { PaperHeader } from '../../components/headers';
import { CONFIRMING_PAYROLLS } from '../../graphql';
import NoRowsMessage from '../../components/text/NoRowsMessage';

const ConfirmingPortfolio = (
  {
    viewTitle,
    queryCustomVariables,
    includeHeaders,
    Actions,
    DocumentActions,
    collapsibleHeaders,
    collapsibleSubHeaders,
  },
) => {
  const [actionFilters, setActionFilters] = useState({});
  return (
    <FingoMainView
      id="confirming-portfolio"
      query={CONFIRMING_PAYROLLS}
      queryCustomVariables={{ ...queryCustomVariables, ...actionFilters }}
      slots={{
        header: PaperHeader,
        actions: Actions,
        table: FingoTable,
      }}
      slotProps={{
        header: {
          viewTitle,
          finder: {
            searchPlaceHolder: 'Buscar',
          },
        },
        actions: {
          actionFilters, setActionFilters,
        },
        table: {
          collapsible: true,
          isRowCollapsible: () => true,
          collapseComponent: (props) => (
            <ConfirmingPortfolioOperations
              {...props}
              customHeaders={collapsibleHeaders}
              collapsibleHeaders={collapsibleSubHeaders}
              DocumentActions={DocumentActions}
            />
          ),
          columns: payrollColumns,
          noRowsMessage: NoRowsMessage,
          initialOrderBy: '-id',
          includeHeaders,
          rowsPerPageOptions: [25, 50, 100],
        },
      }}
    />
  );
};

ConfirmingPortfolio.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  queryCustomVariables: PropTypes.objectOf(PropTypes.any),
  Actions: PropTypes.elementType,
  DocumentActions: PropTypes.elementType,
  includeHeaders: PropTypes.arrayOf(PropTypes.string),
  collapsibleHeaders: PropTypes.arrayOf(PropTypes.string),
  collapsibleSubHeaders: PropTypes.arrayOf(PropTypes.string),
  viewTitle: PropTypes.string,
};

ConfirmingPortfolio.defaultProps = {
  viewTitle: 'Mis nóminas',
  queryCustomVariables: {},
  includeHeaders: [
    'id',
    'createdAt',
    'totalAmount',
    'numberOfOperations',
    'numberOfDocuments',
    'actions',
  ],
  collapsibleHeaders: ['company', 'invoiceCount', 'totalAmount'],
  collapsibleSubHeaders: [
    'folio',
    'dateIssued',
    'expirationDate',
    'amountWithIva',
  ],
  Actions: ConfirmingPortfolioFilters,
  DocumentActions: () => <></>,
};

export default ConfirmingPortfolio;
