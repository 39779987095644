import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { invoiceConfirmingColumns } from './columns';
import FingoTable from '../../components/tables/FingoTable';
import { ConfirmingOperationType } from '../../propTypes';
import NoRowsMessage from '../../components/text/NoRowsMessage';

const ConfirmingPortfolioInvoices = ({ row, customHeaders, DocumentActions }) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const selectionParams = useMemo(() => (DocumentActions ? {
    checkboxSelection: true,
    isRowSelectable: () => (true),
    selectionModel,
    onSelectionModelChange: setSelectionModel,
  } : {}), [selectionModel]);
  return (
    <Stack>
      <DocumentActions documents={row.confirmingSet} documentsSelected={selectionModel} />
      <FingoTable
        rows={row.confirmingSet}
        columns={invoiceConfirmingColumns}
        initialOrderBy="id"
        includeHeaders={customHeaders}
        density="compact"
        noRowsMessage={NoRowsMessage}
        customPaperBackground="transparent"
        pagination={false}
        loading={false}
        {...selectionParams}
      />
    </Stack>
  );
};

ConfirmingPortfolioInvoices.propTypes = {
  row: ConfirmingOperationType,
  customHeaders: PropTypes.arrayOf(PropTypes.string),
  DocumentActions: PropTypes.element,
};

ConfirmingPortfolioInvoices.defaultProps = {
  row: {},
  customHeaders: [
    'folio',
    'dateIssued',
    'expirationDate',
    'amountWithIva',
  ],
  DocumentActions: null,
};

export default ConfirmingPortfolioInvoices;
