import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { operationColumns } from './columns';
import ConfirmingPortfolioInvoices from './ConfirmingPortfolioInvoices';
import { UPDATE_CONFIRMING_OPERATION } from '../../graphql';
import FingoTable from '../../components/tables/FingoTable';
import { ConfirmingPayrollType } from '../../propTypes';
import NoRowsMessage from '../../components/text/NoRowsMessage';

const ConfirmingPortfolioOperations = (
  { row, customHeaders, collapsibleHeaders, DocumentActions },
) => {
  const [updateOperationMutation] = useMutation(UPDATE_CONFIRMING_OPERATION);
  const updateOperation = useCallback(({ contactEmail, bankAccountId, row: modifiedRow }) => {
    if (
      contactEmail === modifiedRow.contactEmail && bankAccountId === modifiedRow.bankAccount?.id
    ) {
      return;
    }
    updateOperationMutation({ variables: {
      operations: [{
        operationId: modifiedRow.id,
        bankAccountId,
        contactEmail,
      }] } });
  }, []);
  return (
    <FingoTable
      rows={row.operations}
      columns={operationColumns(updateOperation)}
      initialOrderBy="id"
      includeHeaders={customHeaders}
      density="compact"
      noRowsMessage={NoRowsMessage}
      customPaperBackground="transparent"
      collapsible
      isRowCollapsible={() => true}
      collapseComponent={(props) => (
        <ConfirmingPortfolioInvoices
          customHeaders={collapsibleHeaders}
          DocumentActions={DocumentActions}
          {...props}
        />
      )}
      pagination={false}
      loading={false}
    />
  );
};

ConfirmingPortfolioOperations.propTypes = {
  row: ConfirmingPayrollType,
  customHeaders: PropTypes.arrayOf(PropTypes.string),
  collapsibleHeaders: PropTypes.arrayOf(PropTypes.string),
  DocumentActions: PropTypes.element,
};

ConfirmingPortfolioOperations.defaultProps = {
  row: {},
  customHeaders: [
    'company',
    'totalAmount',
    'invoiceCount',
    'email',
    'bankAccount',
  ],
  collapsibleHeaders: [
    'folio',
    'dateIssued',
    'expirationDate',
    'amountWithIva',
  ],
  DocumentActions: () => <></>,
};

export default ConfirmingPortfolioOperations;
