import React, { useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useSelectedCompany, useSnackBars } from '../../../../hooks';
import { EXECUTE_CONFIRMING, UPDATE_CONFIRMING_OPERATION } from '../../../../graphql';
import { formatGraphQlDate, formatMoney, toPercentage } from '../../../../helpers';
import TermInput from '../../../../components/inputs/TermInput';
import { FingoDialog } from '../../../../components/dialogs';
import ConfirmingPortfolioOperations from '../../ConfirmingPortfolioOperations';
import { ConfirmingPayrollType } from '../../../../propTypes';

const ConfirmingUploadSidebar = ({ open, setOpen, row, simulationView, ...props }) => {
  const [termDate, setTermDate] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const { addAlert } = useSnackBars();
  const history = useHistory();
  const [executeConfirming, { loading: loadingExecution }] = useMutation(
    EXECUTE_CONFIRMING,
    {
      onCompleted: () => {
        setOpen(false);
        addAlert({ id: 'confirming-executed', severity: 'success', message: 'Confirming operado con éxito' });
        history.push('/app/purchases/confirming/summary');
      },
      onError: ({ message }) => {
        setOpen(false);
        addAlert({ id: 'confirming-executednt', severity: 'error', message });
      },
    },
  );
  const selectedCompany = useSelectedCompany();
  const { monthlyRate } = useMemo(
    () => (selectedCompany?.currentCompanyConfirmingCreditLine || {}),
    [selectedCompany],
  );
  const interestAmount = useMemo(
    () => ((termDate.term || 0) / 30) * (monthlyRate / 100) * row.totalAmount,
    [termDate, monthlyRate],
  );
  const confirmingOperationIds = useMemo(() => row.operations.map(({ id }) => id));
  const defaultDate = useMemo(() => row.operations[0]?.expirationDate);
  const [updateConfirmingOperations] = useMutation(UPDATE_CONFIRMING_OPERATION, { variables: {
    operations: confirmingOperationIds.map(
      ((id) => ({ operationId: id, expirationDate: formatGraphQlDate(termDate.date) })),
    ) } });
  return (
    <Drawer
      open={open}
      onClose={() => { setOpen(false); }}
      anchor="right"
      PaperProps={{
        sx: { width: { xs: '100%', md: '50%', lg: '30%' } },
      }}
      sx={{ zIndex: 1100, position: 'absolute' }}
      {...props}
    >
      <Stack my={2} direction="row" alignItems="center">
        <IconButton onClick={() => setOpen(false)} size="large">
          <ArrowBackIos />
        </IconButton>
        <Typography color="primary" variant="h6">
          Confirming con financiamiento
        </Typography>
      </Stack>
      <Stack spacing={4} width="80%" alignSelf="center">
        <Typography>
          <b>
            Revisa las condiciones para el financiamiento
          </b>
        </Typography>
        <Stack spacing={2}>
          <Stack justifyContent="space-between" direction="row">
            <Typography>
              Cantidad de facturas
            </Typography>
            <Typography>
              {row.totalCount}
            </Typography>
          </Stack>
          <Stack justifyContent="space-between" direction="row">
            <Typography>
              Monto total
            </Typography>
            <Typography>
              ${formatMoney(row.totalAmount)}
            </Typography>
          </Stack>
          <TermInput
            value={termDate}
            setValue={setTermDate}
            defaultDate={defaultDate}
          />
          <Stack justifyContent="space-between" direction="row">
            <Typography>
              Tasa
            </Typography>
            <Typography>
              {toPercentage(monthlyRate / 100)}
            </Typography>
          </Stack>
          <Stack justifyContent="space-between" direction="row">
            <Typography>
              Intereses
            </Typography>
            <Typography>
              ${formatMoney(interestAmount)}
            </Typography>
          </Stack>
        </Stack>
        <Stack justifyContent="space-between" direction="row">
          <Typography color="primary">
            <b>
              Monto a depositar al final del confirming
            </b>
          </Typography>
          <Typography color="primary">
            <b>
              ${formatMoney(row.totalAmount + interestAmount)}
            </b>
          </Typography>
        </Stack>
        {simulationView
          ? (
            <>
              <Stack direction="row" spacing={1}>
                <Button
                  disabled
                  fullWidth
                  variant="outlined"
                  size="small"
                >
                  Descargar
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  to={{
                    pathname: '/app/payroll-profile',
                    search: `?payrollId=${row.id}`,
                  }}
                  onClick={updateConfirmingOperations}
                  component={Link}
                >
                  Ir a Financiar →
                </Button>

              </Stack>
              <Button
                size="small"
                variant="link"
                component={Link}
                onClick={() => setOpen(false)}
              >
                <u>Volver</u>
              </Button>
            </>
          )
          : (
            <Stack direction="row" spacing={1}>
              <Button
                fullWidth
                variant="outlined"
                size="small"
                onClick={() => setOpenModal(true)}
              >
                Ver detalle
              </Button>
              <LoadingButton
                fullWidth
                variant="contained"
                size="small"
                loading={loadingExecution}
                onClick={() => executeConfirming(
                  { variables:
                    {
                      expirationDate: formatGraphQlDate(termDate.date),
                      payrollId: row.id } },
                )}
              >
                Operar nómina
              </LoadingButton>
            </Stack>
          )}
      </Stack>
      <FingoDialog
        open={openModal}
        title="Confirma si deseas continuar"
        handleClose={() => setOpenModal(false)}
        maxWidth="lg"
        PaperProps={{
          sx: {
            borderRadius: 4,
            maxWidth: '100%',
            height: '80%',
          },
        }}
      >
        <ConfirmingPortfolioOperations
          row={row}
          customHeaders={['company', 'invoiceCount', 'totalAmount']}
        />
      </FingoDialog>
    </Drawer>
  );
};

ConfirmingUploadSidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  row: ConfirmingPayrollType.isRequired,
  simulationView: PropTypes.bool,
};

ConfirmingUploadSidebar.defaultProps = {
  simulationView: true,
};

export default ConfirmingUploadSidebar;
