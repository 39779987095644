import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import Mail from '@mui/icons-material/Mail';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSnackBars } from '../../../../hooks';
import { EXPORT_CONFIRMING_PAYROLL, SEND_CESSION_REMINDER_MAIL } from '../../../../graphql';
import { openUrlAndDownload } from '../../../../helpers';
import { DownloadExcelIconButton, LoadingIconButton } from '../../../../components/buttons';

const PendingConfirmingActions = ({ payrollId, confirmingIds, mailDisabled }) => {
  const { addAlert } = useSnackBars();
  const [exportConfirmingPayroll, { loading: loadingExcel }] = useLazyQuery(
    EXPORT_CONFIRMING_PAYROLL,
    { onCompleted: (data) => openUrlAndDownload(data.exportConfirmingPayroll, 'payrollWorkbook') },
  );
  const [sendCessionReminderMail, { loading: loadingMail }] = useMutation(
    SEND_CESSION_REMINDER_MAIL,
    { onError: ({ message }) => addAlert({ id: 'new-leads', message }),
      onCompleted: () => addAlert({ id: 'new-leads', message: 'Correos envíados con éxito' }) },
  );
  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      {payrollId && (
      <DownloadExcelIconButton
        title="Descargar excel de nómina"
        loading={loadingExcel}
        downloadFunction={() => exportConfirmingPayroll({ variables: { payrollId } })}
      />
      )}
      <Tooltip
        title="Enviar mail de recordatorio"
      >
        <LoadingIconButton
          color="primary"
          loading={loadingMail}
          disabled={mailDisabled}
          onClick={() => sendCessionReminderMail({ variables: { confirmingIds } })}
        >
          <Mail />
        </LoadingIconButton>
      </Tooltip>
    </Stack>
  );
};

PendingConfirmingActions.propTypes = {
  payrollId: PropTypes.string,
  confirmingIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  mailDisabled: PropTypes.bool,
};

PendingConfirmingActions.defaultProps = {
  payrollId: null,
  mailDisabled: false,
};

export default PendingConfirmingActions;
