import Save from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { UPDATE_CONFIRMING_OPERATION } from '../../../../graphql';
import { formatGraphQlDate } from '../../../../helpers';

const ChangeConfirmingOperations = ({ confirmingOperationIds, defaultDate }) => {
  const [date, setDate] = useState(defaultDate);
  const [updateConfirmingOperations] = useMutation(UPDATE_CONFIRMING_OPERATION, { variables: {
    operations: confirmingOperationIds.map(
      ((id) => ({ operationId: id, expirationDate: formatGraphQlDate(date) })),
    ) } });
  const disabled = useMemo(
    () => (
      !date?.isValid()
      || (date?.toISOString() === defaultDate?.toISOString())
    || (!date)),
    [date],
  );
  useEffect(() => setDate(defaultDate), [defaultDate]);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <DatePicker
        value={date}
        onChange={setDate}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
          />
        )}
      />
      <IconButton
        color="secondary"
        size="small"
        disabled={disabled}
        onClick={updateConfirmingOperations}
      >
        <Save />
      </IconButton>
    </Stack>
  );
};

ChangeConfirmingOperations.propTypes = {
  confirmingOperationIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultDate: PropTypes.instanceOf(moment),
};

ChangeConfirmingOperations.defaultProps = {
  defaultDate: null,
};

export default ChangeConfirmingOperations;
