import Traffic from '@mui/icons-material/Traffic';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { APPROVE_CONFIRMING } from '../../../../graphql';

const ApproveConfirming = ({ payroll }) => {
  const [approveConfirming] = useMutation(APPROVE_CONFIRMING, { variables: {
    payrollId: payroll?.id } });
  if (!payroll?.statusInfo.CREATED) {
    return <></>;
  }
  return (
    <Tooltip title="Aprobar peaje nómina de confirming">
      <IconButton onClick={approveConfirming}>
        <Traffic />
      </IconButton>
    </Tooltip>
  );
};

ApproveConfirming.propTypes = {
  payroll: PropTypes.shape({
    id: PropTypes.number,
    statusInfo: PropTypes.objectOf(PropTypes.number),
  }).isRequired,
};

export default ApproveConfirming;
